@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.feedback-container {
  margin: 80px 0 80px 0;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  border: 0.5px solid #E7E7E7;
  background: #F9F9F9;
  font-family: RobotoRegular;

  @media (max-width: $media-sm) {
    margin: 64px 0 32px 0;
    gap: 24px;
  }

  .feedback-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h2 {
      font-size: 32px;
      margin: 0;

      @media (max-width: $media-sm) {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      color: #6C6C6E;
      text-align: center;
      line-height: 24px;
      margin: 0;

      @media (max-width: $media-sm) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .feedback-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 672px;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .number-format {
        font-size: 26px; // Размер шрифта для цифр в маске
        color: rgb(255, 32, 32); // Цвет цифр в маске (установите по вашему выбору)
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        pointer-events: none;
      }

      span {
        font-size: 12px;
        color: #6C6C6E;
        padding: 0px 16px;
      }

      input {
        border-radius: 12px;
        border: 1px solid #E7E7E7;
        font-size: 18px;
        padding: 8px 16px;
        background: #FFF;
        margin-bottom: 16px;
        height: 34px;
        max-width: 638px;
      }

      input::placeholder {
        font-size: 18px;
      }
    }

    button {
      height: 56px;
      padding: 16px;
      width: 100%;
      border-radius: 12px;
      background: #EB3B66;
      border: none;
      font-size: 18px;
      color: #FFF;
      cursor: pointer;
      transition: 0.2s;
    }

    button:hover {
      background: #f72f61;
      transition: 0.2s;
    }

    button:disabled {
     opacity: 0.8;
    }

    .buisness {
      background: #0F2847;
    }

    .buisness:hover {
      background: #163863;
      transition: 0.2s;
    }

    .message {
      margin-bottom: 16px;
    }

    .error-message {
      margin: -16px 0 16px 0;
      color: #E74C3C !important;
    }
  }
}
