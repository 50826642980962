@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.benefits-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 40px;

  @media (max-width: $media-sm) {
    padding-top: 32px;
  }

  .block-shape {
    border-radius: 32px 12px 12px 12px;
    border: 0.5px solid #E7E7E7;
    background: #F9F9F9;
  }

  .shape {
    background: #F9F9F9;
    border-radius: 12px;
    border: 0.5px solid #E7E7E7;
  }

  .benefit-list {
    display: flex;
    gap: 16px;

    @media (max-width: $media-sm) {
      display: none;
    }

    .benefit {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      gap: 40px;
      width: 100%;
      color: #3B3B3D;
      font-family: RobotoRegular;

      img {
        max-width: 32px;
        height: auto;
      }
    }
  }

  .instruction-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 48px;

    @media (max-width: $media-md) {
      padding: 32px 24px 0 24px;
    }

    @media (max-width: $media-sm) {
      flex-direction: column;
      text-align: center;
    }

    .benefits-text-block {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (max-width: $media-sm) {
        br {
          display: none;
        }
      }

      h2 {
        font-size: 32px;
        margin: 0;
        font-family: RobotoRegular;

        @media (max-width: $media-lg) {
          font-size: 28px;
          line-height: 36px;
        }

        @media (max-width: $media-md) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      p {
        font-size: 20px;
        color: #6C6C6E;
        font-family: RobotoRegular;
        margin: 0;

        @media (max-width: $media-lg) {
          font-size: 18px;
          line-height: 24px;
        }

        @media (max-width: $media-md) {
          font-size: 15px;
          line-height: 20px;
        }

        @media (max-width: $media-sm) {
          font-size: 17px;
          line-height: 24px;
        }
      }
    }

    .benefits-img-block {
      display: flex;
      height: 100%;
      padding-top: 24px;

      img {
        width: 100%;
        max-width: 560px;
        height: auto;

        @media (max-width: $media-xl) {
          max-width: 512px;
        }

        @media (max-width: $media-lg) {
          max-width: 420px;
        }

        @media (max-width: $media-md) {
          max-width: 348px;
          max-height: 230px;
        }
      }
    }
  }
}
