@import "./part/_variables.scss";

body {
  margin: 0;
}

.width-limit {
  max-width: 1136px;
  margin: 0 auto;
  padding: 0px 16px;

  @media (max-width: $media-xl) {
    max-width: 1040px;
  }

  @media (max-width: $media-lg) {
    max-width: 856px;
  }

  @media (max-width: $media-md) {
    max-width: 704px;
  }

  a {
    text-decoration: none;
  }

  button {
    img {
      user-select: none;
    }
  }
}

