@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.promo-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 64px 0 40px 0;

  @media (max-width: $media-sm) {
    flex-direction: column-reverse;
    margin: 64px 0 32px 0;
  }

  &-benefits {
    display: flex;
    flex-direction: column;
    max-width: 560px;

    @media (max-width: $media-md) {
      align-items: center;
    }

    @media (max-width: $media-sm) {
      text-align: center;
    }

    h1 {
      font-size: 40px;
      line-height: 48px;
      font-family: RobotoRegular;

      @media (max-width: $media-lg) {
        font-size: 32px;
        line-height: 40px;
        margin: 12px 0;
      }

      @media (max-width: $media-md) {
        br {
          display: none;
        }
      }

      @media (max-width: $media-sm) {
        font-size: 28px;
        line-height: 36px;
      }
    }

    p {
      color: #6C6C6E;
      font-size: 20px;
      line-height: 28px;
      font-family: RobotoLight;
      margin: 0;

      @media (max-width: $media-lg) {
        font-size: 17px;
        line-height: 24px;
      }

      @media (max-width: $media-md) {
        br {
          display: none;
        }
      }
    }

    button {
      border-radius: 12px;
      background: #0F2847;
      border: none;
      max-width: 295px;
      width: 100%;
      padding: 16px;
      color: #fff;
      font-family: RobotoRegular;
      font-size: 18px;
      line-height: 24px;
      margin-top: 32px;
      cursor: pointer;
      transition: 0.2s all;

      &.raspberry-button {
        background: #eb3b66;
      }
    }

    button:hover {
      background: #14355e;
      transition: 0.2s all;

      &.raspberry-button {
        background: #f72f61;
      }
    }
  }

  img {
    width: 100%;
    max-width: 580px;
    height: auto;

    @media (max-width: $media-xl) {
      max-width: 512px;
    }

    @media (max-width: $media-lg) {
      max-width: 420px;
    }

    @media (max-width: $media-md) {
      max-width: 348px;
    }
  }

  &-limit-btn {
    box-sizing: border-box;
    padding: 16px;
    background-color: #E4F8E7;
    border-radius: 12px;
    width: 324px;
    margin-top: 32px;

    @media (max-width: $media-sm) {
      width: 100%;
    }

    h2 {
      margin: 0 0 24px;
      font-family: RobotoRegular;
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;

      @media (max-width: $media-md) {
        font-size: 24px;
      }
    }

    button {
      margin: 0;
      max-width: 100%;
    }
  }
}
