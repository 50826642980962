@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";


.header-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  line-height: 20px;

  .mob-icon {
    display: none;
    position: relative;
    right: 15px;
  }

  @media (max-width: $media-md) {
    .mob-icon {
      display: block;
    }
  }

  .header-separating-container {
    display: flex;

    @media (max-width: $media-md) {
      display: none;
    }
  }

  .header-anchors-container {
    display: flex;
    align-items: center;
    margin-left: 24px;
    gap: 8px;

    .header-anchor {
      padding: 0 16px;

      a {
        font-family: RobotoRegular;
        cursor: pointer;
        color: #0A0A0D;
      }
    }
  }

  .header-buttons {
    display: flex;
    gap: 8px;

    button {
      display: flex;
      padding: 8px 13px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 24px;
      border: 0.5px solid #E7E7E7;
      background: #FFF;
      cursor: pointer;
      color: #0A0A0D;
    }

    .header-login-button {
      padding: 8px;
    }

    .desc-btn {
      @media (max-width: $media-md) {
        display: none;
      }

      &__lang {
        width: 20px;
        height: 20px;
      }

    }

    .mob-btn {
      display: none;

      @media (max-width: $media-md) {
        display: flex;
      }
    }

    .burger-btn {
      display: none;
      padding: 12px 8px;
      cursor: pointer;

      @media (max-width: $media-md) {
        display: flex;
      }
    }

    .burger-exit-btn {
      display: none;
      padding: 10px 10px;
      cursor: pointer;

      @media (max-width: $media-md) {
        display: flex;
      }
    }

    .active {
      display: none;
    }
  }

  .mob-vers {
    display: none;

    @media (max-width: $media-md) {
      display: flex;
    }
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    width: 100%;
    max-width: 768px;
    height: 0;
    background: #FFF;
    overflow: hidden;
    transition: height 0.3s ease;

    @media (min-width: $media-md) {
      display: none;
    }

    .header-buttons {
      width: 100%;

      button {
        width: 100%;
        // min-width: 343px;
      }
    }

    .menu-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 32px;

      .menu-link {
        display: flex;
        padding: 16px 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-top: 0.5px solid #DADADB;
        font-family: RobotoRegular;
        font-size: 14px;
        line-height: 20px;
        margin: 0 5px;
      }

      .menu-link:after {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9 18L15 12L9 6" stroke="%230A0A0D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        position: relative;
        top: 3.5px;
      }
    }

    .login-btn {
      border-top: 0.5px solid #DADADB;
      padding-top: 32px;
    }
  }

  .mobile-menu.open {
    height: 480px;
  }
}
