
@font-face {
  font-family: 'RobotoThin'; /* Гарнитура шрифта */
  src: url('../../assets/fonts/Roboto/Roboto-Thin.ttf'); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: 'RobotoLight'; /* Гарнитура шрифта */
  src: url('../../assets/fonts/Roboto/Roboto-Light.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoRegular'; /* Гарнитура шрифта */
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium'; /* Гарнитура шрифта */
  src: url('../../assets/fonts/Roboto/Roboto-Medium.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold'; /* Гарнитура шрифта */
  src: url('../../assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
