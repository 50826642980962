@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.preloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed; /* или absolute */
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: white;
}

body.no-scroll {
  overflow: hidden;
}
.preloader-svg {
  animation: scaleAnimation 1.3s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
