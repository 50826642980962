@import "../../styles/index.scss";
@import "./slick.scss";

.partners-carousel-container {
  margin: 80px 0 0 0;
  width: 100%;

  h2 {
    font-family: RobotoRegular;
    font-size: 32px;

    @media (max-width: $media-md) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.c-slick {
  margin-left: -20px;
}

.c-slick__slide {
  padding-left: 20px;
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
}

.slider-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 32px 12px 12px 12px;
  border: 0.5px solid #e7e7e7;
  background: #f9f9f9;
  padding: 20px 0;
  max-width: 272px;

  @media (max-width: $media-sm) {
    width: 180px !important;

    img {
      max-width: 140px;
    }
  }
}

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: -270%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;

  @media (max-width: $media-md) {
    display: none !important;
  }
}

.slick-prev {
  top: -396% !important;
  right: 45px !important;
}

.slick-next {
  right: -1px !important;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
}

.slick-prev {
  right: 20px;
  // z-index: 1000;
  border-radius: 24px;
  width: 40px;
  height: 40px;
  border: 0.5px solid var(--contrast-ratio-pl-black-10-e-7-e-7-e-7, #E7E7E7);
  background: var(--ui-elements-ui-white-ffffff, #FFF);
  transform: rotate(180deg);
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  position: relative;
  top: 2px;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9 18L15 12L9 6" stroke="%230A0A0D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
  position: relative;
  top: 2px;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9 18L15 12L9 6" stroke="%230A0A0D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.slick-next {
  right: -25px;
  border-radius: 24px;
  width: 40px;
  height: 40px;
  border: 0.5px solid var(--contrast-ratio-pl-black-10-e-7-e-7-e-7, #E7E7E7);
  background: var(--ui-elements-ui-white-ffffff, #FFF);
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

[dir=rtl] .slick-next:before {
  content: '←';
}

.slick-dotted.slick-slider {
  // margin-bottom: 30px;
  max-height: 16px !important;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: #000;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
