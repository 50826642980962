.loader {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 2px solid #E7E7E7;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
