@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.footer {
  background-color: #F9F9F9;
  border-top: 0.5px solid #E7E7E7;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-container {
    max-width: 1136px;
    width: 100%;
  
    @media (max-width: $media-xl) {
      max-width: 1040px;
    }
  
    @media (max-width: $media-lg) {
      max-width: 856px;
    }
  
    @media (max-width: $media-md) {
      max-width: 704px;
    }
    
    .footer-head-block {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .lang-icon {
        width: 20px;
        height: 20px;
      }

      button {
        display: flex;
        padding: 8px 13px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 24px;
        border: 0.5px solid #E7E7E7;
        background: #FFF;
        cursor: pointer;
        color: #0A0A0D;
      }
    }

    .footer-info-block {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 32px 0;

      @media (max-width: $media-sm) {
        flex-direction: column;
        gap: 32px;
      }

      .nav-links {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .nav-label {
          color: #6C6C6E;
          font-family: RobotoMedium;
          font-size: 14px;
          line-height: 20px;
          user-select: none;

          &.anchor-link {
            margin-top: 24px;

            & > a {
              text-decoration: none;
              color: inherit;
            }

            &.mt-0 {
              margin-top: 0;
            }
          }
        }
        
        .footer-anchor {
          color: #0A0A0D;
          font-family: RobotoRegular;
          font-size: 14px;
          line-height: 20px;
          user-select: none;
        }

        .anchor-link {
          cursor: pointer;
          transition: .2s all;
        }

        .anchor-link:hover {
          color: #EB3B66;
        }
      }

      .social-links {
        padding-top: 16px;
      }

      .d-flex-end-center {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 16px;
        gap: 8px;
        padding-top: 4px;

        img {
          cursor: pointer;
        }

        @media (max-width: $media-sm) {
          justify-content: flex-start;
        }
      }
    }
  }
}
