@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.questions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 80px;

  @media (max-width: $media-sm) {
    margin-top: 64px;
  }

  h1 {
    font-family: RobotoRegular;
    color: #0A0A0D;
    text-align: center;
    font-size: 32px;

    @media (max-width: $media-sm) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  .question-answer-list {
    display: flex;
    flex-direction: column;
    color: #0A0A0D;
    margin-top: 32px;
    max-width: 752px;

    @media (max-width: $media-sm) {
      margin-top: 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;

      .questions-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        border: 0.5px solid #E7E7E7;
        background: #F9F9F9;
        padding: 16px;
        cursor: pointer;

        p {
          font-family: RobotoRegular;
          font-size: 18px;
          margin: 0;
        }
      }

      .questions-label:after {
        content: url('../../assets/img/arrow.svg');
        transition: 0.2s;
      }

      .active:after {
        transition: 0.2s;
        transform: rotate(180deg);
      }

      .answer-block {
        border-radius: 12px;
        border: 0.5px solid #E7E7E7;
        background: #F9F9F9;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;

        &__tip {
          font-size: 13px !important;
        }

        ul, p {
          color: #545456;
          font-family: RobotoRegular;
          font-size: 15px;

          a {
            font-family: RobotoBold;
            color: #EB3B66;
            cursor: pointer;
          }
        }
      }

      .answer-block.active {
        opacity: 1;
        height: auto;
        padding: 16px;
      }
    }
  }
}
