@import "../../styles/index.scss";
@import "../../styles/part/fonts.scss";

.banner-container {
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 24px;
  background: #0F2847;
  margin-top: 225px;
  padding: 0 40px;

  @media (max-width: $media-lg) {
    padding: 0 24px;
  }

  @media (max-width: $media-sm) {
    flex-direction: column-reverse;
    margin-top: 175px;
  }

  img {
    width: 100%;
    max-width: 512px;
    height: auto;
    margin-top: 40px;

    @media (max-width: $media-xl) {
      max-width: 430px;
    }

    @media (max-width: $media-lg) {
      max-width: 420px;
    }

    @media (max-width: $media-md) {
      max-width: 318px;
    }

    @media (max-width: $media-sm) {
      margin-top: 14px;
    }
  }

  .banner-benefits {
    display: flex;
    flex-direction: column;
    color: #DADADB;
    padding: 0 55px;

    @media (max-width: $media-lg) {
      padding: 0 24px;
    }

    @media (max-width: $media-sm) {
      text-align: center;
      padding-top: 32px;
    }

    h1 {
      color: #FFF;
      font-size: 40px;
      font-family: RobotoRegular;

      @media (max-width: $media-lg) {
        font-size: 32px;
        line-height: 40px;
        margin: 0;
      }

      @media (max-width: $media-md) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: $media-sm) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      font-size: 20px;
      font-family: RobotoLight;

      @media (max-width: $media-lg) {
        font-size: 17px;
        line-height: 24px;
        margin: 12px 0;
      }

      @media (max-width: $media-md) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .s-size-text {
      font-size: 16px;
      margin: 0;

      @media (max-width: $media-md) {
        font-size: 12px;
      }
    }
  }
}

.hide {
  margin-top: 115px;
}
